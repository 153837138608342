<template>
    <div>
        <TableList
            :columns="columns"
            :get_table_list="this.get_table_list"
            :form_data_seo="form_data_seo"
            :rowSelection="rowSelection"
            :slot_table_list="['operation','status','visit_num','order_price','investment_price','binding_num','is_line']"
            :submit_preprocessing="submit_preprocessing"
            ref="list"
            :rowKey="'id'"
        >
            <template slot="table_before">
                <a-alert
                    v-if="selectedRowKeys.length"
                    style="margin-bottom:10px"
                    :message="'已选择'+selectedRowKeys.length+'项'"
                    type="info"
                    showIcon
                />
            </template>
            <template slot="left_btn">
              <!--  <a-button
                    @click="add()"
                    type="primary"
                >添加客户</a-button>
                <a-button
                    type="primary"
                    style="margin-left: 15px;"
                    @click="start"
                    :disabled="!hasSelected"
                    :loading="loading"
                >批量更改业务员</a-button> -->
                <!-- <a-button
                    v-if="this.$store.state.page_auth.batch"
                    style="margin-left: 15px;"
                    @click="handle_transfer"
                >转移客户</a-button> -->

				<!-- <a-popconfirm title="您确定要删除吗?" v-if="this.$store.state.page_auth.delete" @confirm="handle_all_delete">
                    <a-button
					:disabled="!hasSelected"
					 type="primary"
                    	style="margin-left: 15px;">批量删除</a-button>
                </a-popconfirm> -->

				<!-- <a-button
					@click="handle_all_enable"
					:disabled="!hasSelected"
					 type="primary"
                    	style="margin-left: 15px;">批量启用</a-button> -->
				<!-- <a-popconfirm title="您确定要删除吗?" v-if="this.$store.state.page_auth.delete" @confirm="handle_all_delete"> -->
                <!-- </a-popconfirm> -->
				<!-- <a-popconfirm title="您确定要封存吗?" @confirm="handle_all_sealed">
					<a-button
						style="margin-left: 15px;"
						:disabled="!hasSelected"
						type="primary"
					>批量封存</a-button>
				</a-popconfirm> -->
            </template>
            <template slot="right_btn">
                <export-table
                    v-if="this.$store.state.page_auth.export"
                    type="pre"
                    :url="'/manage/crm.export/customerExport' | export_form"
                ></export-table>
                <import-upload
                    v-if="this.$store.state.page_auth.import"
                    url="/manage/crm.export/customerImport"
                ></import-upload>
                <export-table
                    v-if="this.$store.state.page_auth.import"
                    name="模板下载"
                    url="/static/excel/客户导入模板.xlsx"
                ></export-table>
            </template>
			
			<!-- v-for="item in ['visit_num','','','']" :key="item" -->
			<template  slot="visit_num" slot-scope="data">
				<router-link :to="{path:'/imitation/index',query:{customer:data.record.id,flag:'customer'}}">{{data.text}}</router-link>
            </template>
			<template  slot="order_price" slot-scope="data">
				<router-link :to="{path:'/order/index',query:{customer:data.record.id}}">{{data.text}}</router-link>
            </template>
			<template  slot="investment_price" slot-scope="data">
				<router-link :to="{path:'/approval/investment',query:{customer:data.record.id}}">{{data.text}}</router-link>
            </template>
			<template  slot="binding_num" slot-scope="data">
				<router-link :to="{path:'/good_list/freezer',query:{customer:data.record.id}}">{{data.text}}</router-link>
            </template>
			<template  slot="is_line" slot-scope="data">
				<span v-if="data.text==1">已排线</span>
				<span v-else>未排线</span>
            </template>
            <template slot="status" slot-scope="data">
                <a-tag
                    :color="data.text == -1 ? config.color.error : config.color.primary"
                    v-for="item in status_list"
                    :key="item.key"
                    v-show="item.key == data.text"
                >{{item.value}}</a-tag>
            </template>
			<!-- v-auth="'change'" -->
            <template slot="operation" slot-scope="data">
                <span>
                    <a @click="edit(data.record)">编辑</a>
                </span>
            </template>
        </TableList>

        <!-- 选择用户 -->
        <SelectUser @submit="submit" :visible.sync="user_visible"></SelectUser>

        <!-- 转移客户 -->
        <EditPop
            :form_data="transfer_form_data"
            ref="transfer_form"
            :destroyOnClose="true"
            :visible.sync="transfer_visible"
            @handleSubmit="transfer_submit"
        >
            <div @click="get_uid('uid')" slot="select_uid">
                <a-input v-model="transfer_list.uid"></a-input>
            </div>
            <div @click="get_uid('to_uid')" slot="select_to_uid">
                <a-input v-model="transfer_list.to_uid"></a-input>
            </div>
        </EditPop>
    </div>
</template>

<script>
// @ is an alias to /src
import EditPop from "@/components/EditPop";
import Form from "@/components/Form";
import TableList from "@/components/TableList";
import SelectUser from "@/components/SelectUser";

import { getCustomerList, batchModify, delCustomer, sealedCustomer ,enableCustomer} from "@/api/customer";
import {customer_seller_list}from "@/api/push";
import { getUserList } from "@/api/personnel";

const columns = [
    {
        title: "客户ID",
        dataIndex: "id"
    },
    {
        title: "客户名称",
        dataIndex: "name"
    },
    {
        title: "客户地址",
        dataIndex: "address"
    },
    {
        title: "客户等级",
        dataIndex: "level"
    },
    {
        title: "客户类型",
        dataIndex: "group"
    },
    {
        title: "客户状态",
        dataIndex: "status",
        scopedSlots: {
            customRender: "status"
        }
    }, {
        title: "排线状态",
        dataIndex: "is_line",
        scopedSlots: {
            customRender: "is_line"
        }
    },
    {
        title: "业务员",
        dataIndex: "username"
    },
    {
        title: "部门",
        dataIndex: "department"
    },
    {
        title: "拜访次数",
        dataIndex: "visit_num",
        scopedSlots: {
            customRender: "visit_num"
        }
    },
    {
        title: "订单上报金额",
        dataIndex: "order_price",
        scopedSlots: {
            customRender: "order_price"
        }
    },
    {
        title: "费用投放金额",
        dataIndex: "investment_price",
        scopedSlots: {
            customRender: "investment_price"
        }
    },
    {
        title: "冰柜数量",
        dataIndex: "binding_num",
        scopedSlots: {
            customRender: "binding_num"
        }
    },
    {
        title: "最后拜访时间",
        dataIndex: "last_visit"
    },
    {
        title: "操作",
        dataIndex: "operation",
        scopedSlots: {
            customRender: "operation"
        }
    }
];

let status_list = [
    {
        key: 1,
        value: '运营中'
    },
    {
        key: -1,
        value: '封存'
    },
];

export default {
    name: "Index",
    components: {
        EditPop,
        Form,
        TableList,
        SelectUser
    },
    data() {
        return {
            config: this.$config,
            get_table_list: customer_seller_list,
            get_pop_table_list: getUserList,
            submit_preprocessing: {
                array_to_string: ['department_id', 'group_id']
            },
            columns,

            status_list,
            selectedRowKeys: [],
            loading: false,
            visible: false,
            transfer_type: 'jump_customer',
            transfer_name: 'uid',
            transfer_list: {
                uid: '',
                to_uid: ''
            },
            transfer_form_data: {
                title: '转移客户',
                list: [
                    {
                        type: "slot",
                        name: "select_uid",
                        title: "原业务员",
                        options: {},
                    },
                    {
                        type: "text",
                        name: "uid",
                        hidden: true,
                        title: "原业务员",
                        options: {},
                    },
                    {
                        type: "slot",
                        name: "select_to_uid",
                        title: "新业务员",
                        options: {},
                    },
                    {
                        type: "text",
                        name: "to_uid",
                        hidden: true,
                        title: "新业务员",
                        options: {},
                    },
                ]
            },
            transfer_visible: false,
            user_visible: false,

            form_data_seo: {
                list: [
                    // {
                    //     type: "tree-select",
                    //     name: "department_id",
                    //     title: "部门",
                    //     options: {},
                    //     treeData: [],
                    //     multiple: true
                    // },
                    {
                        type: "text",
                        name: "keyword",
                        title: "客户",
                        placeholder: "客户名称/地址/ID",
                        options: {}
                    },
                    {
                        type: "text",
                        name: "username",
                        title: "业务员",
                        placeholder: "姓名/账号/工号/手机号",
                        options: {}
                    },

                    {
                        type: "select",
                        name: "incumbency_status",
                        title: "在职状态",
                        mode: "default",
                        options: {},
                        list: this.$config.user_status_list
                    },

                    {
                        type: "select",
                        name: "level_id",
                        title: "客户等级",
                        mode: "default",
                        options: {},
                        list: []
                    },
                    // {
                    //     type: "cascader",
                    //     name: "group_id",
                    //     title: "客户类型",
                    //     list: [],
                    //     placeholder: "请选择客户类型",
                    //     mode: "default",
                    //     options: {}
                    // },
                    {
                        type: "select",
                        name: "is_line",
                        title: "排线状态",
                        mode: "default",
                        options: {},
                        list: [
                            {
                                key: "0",
                                value: "未排线"
                            },
                            {
                                key: "1",
                                value: "已排线"
                            },
                        ]
                    },
                    {
                        type: "select",
                        name: "status",
                        title: "客户状态",
                        mode: "default",
                        options: {},
                        list: [
                            {
                                key: "1",
                                value: "运营中"
                            },
                            {
                                key: "-1",
                                value: "封存"
                            },
                        ]
                    },
                    // {
                    //     type: "select",
                    //     name: "is_department",
                    //     title: "是否绑定部门",
                    //     mode: "default",
                    //     options: {},
                    //     list: [
                    //         {
                    //             key: "2",
                    //             value: "已绑定"
                    //         },
                    //         {
                    //             key: "1",
                    //             value: "未绑定"
                    //         },
                    //     ]
                    // },
                    {
                        type: "select",
                        name: "is_freezer",
                        title: "是否绑定冰柜",
                        mode: "default",
                        options: {},
                        list: [
                            {
                                key: "1",
                                value: "是"
                            },
                            {
                                key: "0",
                                value: "否"
                            },
                        ]
                    }
                ],
                ...this.$config.form_data_seo
            },
            customer_ids: '',
            select_user: [],
        };
    },

    computed: {
        hasSelected() {
            return this.selectedRowKeys.length > 0;
        },
        rowSelection() {
            const { selectedRowKeys } = this;
            return {
                selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                    this.selectedRowKeys = selectedRowKeys;
                    this.customer_ids = selectedRowKeys.join(',')
                },
                hideDefaultSelections: true,
            };
        }
    },
    async created() {
        Promise.all([this.$method.get_department(), this.$method.get_group(), this.$method.get_level()]).then(res => {
            this.form_data_seo.list.forEach(item => {
                if (item.name == "department_id") {
                    item.treeData = res[0];
                }
                if (item.name == "group_id") {
                    item.list = res[1];
                }
                if (item.name == "level_id") {
                    item.list = res[2];
                }
            });
        });

        this.$nextTick(() => {
            this.$refs.list.pagination.pageSizeOptions = ['10', '20', '50', '100', '200', '300'];
            this.$refs.list.pagination.showSizeChanger = true;
        })
    },
    mounted() {
        // window.addEventListener('popstate', this.handleBackCustomerButton,false);
      },
	  activated() {
	  		this.$refs.list.get_list();
	  },
    methods: {
    	handleBackCustomerButton(){
			if(this.$refs.list){
				this.$refs.list.get_list();
			}
    	},
        handle_transfer() {
            for (let key in this.transfer_list) {
                this.transfer_list[key] = '';
            }

            this.transfer_type = 'jump_user';
            this.transfer_visible = true;
        },
        get_uid(type) {
            this.transfer_name = type;
            this.user_visible = true;
        },
        start() {
            this.user_visible = true
            this.transfer_type = 'jump_customer';

        },
        transfer_submit(e, type = 'jump_user') {
            let obj = {
                type,
            }

            if (type == 'jump_customer') {
                obj.customer_ids = this.customer_ids,
                    obj.uid = e.id;

            } else if (type == 'jump_user') {
                obj = {
                    ...obj,
                    ...e.values
                }
            }
            try {
                batchModify({
                    data: obj,
                    info: true
                }).then(res => {
                    let _this = this;
                    this.user_visible = false;
                    this.transfer_visible = false;
                    this.$refs.list.get_list();

                    if (type == 'jump_customer') {
                        this.selectedRowKeys = [];
                        this.customer_ids = '';
                    }
                })
            } catch {

            }
        },
		handle_all_enable(){
			enableCustomer({
                data: {
                    ids: this.customer_ids
                },
                info: true
            }).then(res => {
                this.$refs.list.get_list();
                this.selectedRowKeys = [];
                this.customer_ids = '';
            })
		},
        handle_all_delete() {
            delCustomer({
                data: {
                    ids: this.customer_ids
                },
                info: true
            }).then(res => {
                this.$refs.list.get_list();
                this.selectedRowKeys = [];
                this.customer_ids = '';
            })
        },
        handle_all_sealed() {
            sealedCustomer({
                data: {
                    ids: this.customer_ids
                },
                info: true
            }).then(res => {
                this.$refs.list.get_list();
                this.selectedRowKeys = [];
                this.customer_ids = '';
            })
        },
        submit({ data }) {
            if (this.transfer_type == 'jump_user') {
                let value = {};
                value[this.transfer_name] = data.id;
                this.$refs.transfer_form.setFieldsValue(value)
                this.transfer_list[this.transfer_name] = data.username
                this.user_visible = false;
                return false;
            }

            this.transfer_submit(data, 'jump_customer');
        },
        add() {
            this.$router.push("/customer/index_edit");
        },
        edit(key) {
            this.$router.push("/customer/index_edit?id=" + key.id);
        },
    }
};
</script>

<style lang="less">
@import url("../../../assets/less/app.less");
</style>